import React, { useState, useRef, useLayoutEffect } from "react"
import { flushSync } from "react-dom"
import { Popover as HeadlessPopover } from "@headlessui/react"
import Icon from "../../../components/Selfcare/Common/Icon"
import { useTheme } from "@mui/material"
import styles from "./styles.module.css"
import { useFloating, shift, flip, size } from "@floating-ui/react-dom"
import globalState from "../../../state/GlobalState"
import { useState as useGlobalState } from "@hookstate/core"

const Popover = ({ heading, children }) => {
  const isNorway = useGlobalState(globalState).isNorway

  const theme = useTheme()

  const [maxHeight, setMaxHeight] = useState(null)
  const borderRadius = 30 // Compensate for the border radius of the dialogs
  const { refs, floatingStyles } = useFloating({
    middleware: [
      size({
        apply({ availableHeight }) {
          flushSync(() => setMaxHeight(availableHeight - borderRadius))
        }
      }),
      shift(),
      flip()
    ]
  })

  const [maxContentHeight, setMaxContentHeight] = useState(null)
  const headingContainerRef = useRef(null)
  const hrRef = useRef(null)
  useLayoutEffect(() => {
    const headingHeight = headingContainerRef.current?.offsetHeight || 0
    const hrHeight = hrRef.current?.offsetHeight || 0
    const availableHeight = maxHeight - headingHeight - hrHeight

    setMaxContentHeight(availableHeight)
  }, [maxHeight])
  return (
    <HeadlessPopover
      style={{
        display: "inline-flex"
      }}
    >
      <HeadlessPopover.Button
        ref={refs.setReference}
        aria-label={isNorway ? "Hjelp" : "Hjälp"}
      >
        <Icon name="information" size={24} color={theme.palette.primary.main} />
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        ref={refs.setFloating}
        className={styles.panel}
        style={{ ...floatingStyles, maxHeight }}
      >
        <div ref={headingContainerRef} className={styles.headingContainer}>
          <p>{heading}</p>
          <HeadlessPopover.Button aria-label={isNorway ? "Lukk" : "Stäng"}>
            <Icon name="close" size={24} color={theme.palette.primary.main} />
          </HeadlessPopover.Button>
        </div>
        <hr ref={hrRef} role="presentation" />
        <div
          className={styles.contentContainer}
          style={{ maxHeight: `${maxContentHeight}px` }}
        >
          {children}
        </div>
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  )
}

export default Popover
