import React, { useRef, useState, useEffect } from "react"
import produce from "immer"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { useTheme } from "@mui/material/styles"
import globalState from "../state/GlobalState"
import { useState as useGlobalState } from "@hookstate/core"
import auth from "../auth"
import Version from "../version"
import { useConnect } from "dotnetify"
import ServerState from "../controls/ServerState"
import SparkleDialog from "../controls/components/SparkleDialog"
import TopContainer from "../controls/TopContainer"
import MasonryShoppingLayout from "../controls/MasonryShoppingLayout"
import Icon from "../components/Selfcare/Common/Icon"
import { ShoppingGroup } from "../controls/ShoppingGroup"
import { ShoppingBox } from "../controls/ShoppingBox"
import Phone from "../assets/img/Phone.png"

import SimCard from "../assets/img/svg/simcard.svg"
import KraftriketLogo from "../assets/img/Kraftriket logo.png"
import NextoryLogo from "../assets/img/Nextory_logo.png"
import NextoryFirkant from "../assets/img/NextoryFirkant.png"
import StorytelFirkant from "../assets/img/StorytelFirkant.png"
import InsuranceImage from "../assets/img/Insurance-shopping.png"
import ChiliKompisImg from "../assets/img/ChiliKompis.jpg"
import PrepaidToPostpaidImg from "../assets/img/PrepaidTilPostpaid.jpg"
import HemFixarnaLogo from "../assets/img/Hemfixarna.png"
import StrimLogo from "../assets/img/StrimLogo.png"
import NordVPNLogo from "../assets/img/NordVPN.png"
import PodimoLogo from "../assets/img/Podimo.png"
import S24Base from "../assets/img/S24Base.png"

export default function Shopping() {
    const { vm, state } = useConnect(
        "Shopping",
        {},
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            exceptionHandler: _ => auth.signOut()
        }
    )
    const [localState, internalSetLocalState] = useState({
        disabled: false,
        busy: false,
        showBuyDataButton: false,
        dialogOpen: false,
        ViaplayBuzy: false,
        CancelViaplayDialogOpen: false,
        ActivateViaplayDialogOpen: false
    })
    const setLocalState = o => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0]
            })
        )
    }
    const gstate = useGlobalState(globalState)
    const wDim = useWindowDimensions()
    const theme = useTheme()
    const dialogRef = useRef()
    const SafeMasonryShoppingLayout = ({ children, ...props }) => (
        <MasonryShoppingLayout {...props}>
            {[].concat(children).filter(Boolean)}
        </MasonryShoppingLayout>
    )
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true })
    }
    useEffect(() => {
        if (
            state.OpenWebOrderUrl !== undefined &&
            state.OpenWebOrderUrl !== "" &&
            state.OpenWebOrderUrl !== null
        ) {
            dialogRef.current.openUrl(state.OpenWebOrderUrl)
        }
    }, [state.OpenWebOrderUrl])

    const canOrderSubscription = state.WebOrderUrl && true
    const canChangeSubscription =
        (state.IsNorway && state.IsPostpaid && !state.IsData) || state.IsSweden
    const showKraftriket = state.KraftriketUrl && true
    const showNextory = state.NextoryUrl && true
    const canBuyDataSweden = state.IsSweden && state.CanBuyData
    const canConvertPrepaidToPostpaid = state.CanConvertPrepaidToPostpaid
    const showStorytel = state.ShowStorytel && true
    const insuranceMonthlyFee = state.InsuranceMonthlyFee
    var subscriptionGroupWeight =
        (+canChangeSubscription +
            +canOrderSubscription +
            +canBuyDataSweden +
            +canConvertPrepaidToPostpaid) *
        2 -
        1

    if (subscriptionGroupWeight < 2) subscriptionGroupWeight = 2
    const showWebshopGroup = state.WebShopOpen || state.HasDiscountCoupon
    return (
        <>
            <ServerState
                serverState={state}
                localState={localState}
                setLocalState={setLocalState}
            />
            <SparkleDialog
                ref={dialogRef}
                handleRefresh={handleRefresh}
                subscriptionNo={state.SubscriptionNo}
            />
            {state.Subscription ? (
                <TopContainer
                    title={gstate.IsSweden.get() ? "Handla" : "Handle"}
                    hideprofile={state.WebShopOpen && wDim.phone}
                >
                    <SafeMasonryShoppingLayout columns={wDim.width > 759 ? 2 : 1}>
                        {gstate.IsSweden.get() && (
                            <ShoppingGroup title="Övriga tjänster" weight={3}>
                                <ShoppingBox
                                    title="NordVPN - säkerhetstjänst"
                                    leftContent="Världens snabbaste VPN som scannar filer som du laddar ner, kollar farliga länkar, blockerar annonser samt ger dig tillgång till innehåll och webbplatser på ett säkert sätt."
                                    rightContent={
                                        <div
                                            style={{
                                                display: "flex",
                                                flexFlow: "column nowrap",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div style={{ textAlign: "left", fontWeight: "500" }}>
                                                <div>79 kr/mån</div>
                                                <div>Fyra månader för halva priset!</div>
                                            </div>
                                            <img src={NordVPNLogo} alt="" width={100} />
                                        </div>
                                    }
                                    buttonText="Beställ"
                                    linkAction={() => {
                                        sessionStorage.ServiceNo = "661"
                                        dialogRef.current.showDialog("changeService")
                                    }}
                                />

                                {insuranceMonthlyFee !== null && (
                                    <ShoppingBox
                                        title="Försäkring"
                                        leftContent="En unik mobilförsäkring som gäller för vilken mobilmodell som helst och den bygger på att det inte spelar någon roll om det är en ny eller begagnad mobil om olyckan är framme. Försäkringen gäller i hela världen."
                                        rightContent={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexFlow: "column nowrap",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <div style={{ textAlign: "left", fontWeight: "500" }}>
                                                    <div>{insuranceMonthlyFee}</div>
                                                    <div>En månad gratis</div>
                                                </div>
                                                <Icon
                                                    name="insurance"
                                                    color={theme.palette.primary.main}
                                                    size={48}
                                                />
                                            </div>
                                        }
                                        buttonText="Hantera"
                                        linkAction={() => {
                                            dialogRef.current.showDialog("insurance")
                                        }}
                                    />
                                )}
                                <ShoppingBox
                                    title="Hemfixarna"
                                    leftContent="Prisvärd tekniksupport i hemmet som löser dina utmaningar med tex wifi, routers, inställningar och brandväggar. Boka din hjälp på hemfixarna.se med ett pris från 399 kronor."
                                    rightContent={<img src={HemFixarnaLogo} alt="" width={100} />}
                                    buttonText="Beställ"
                                    linkAction={() => {
                                        dialogRef.current.openUrl(
                                            "https://hemfixarna.se/chilimobilbestallning",
                                            true
                                        )
                                    }}
                                />
                            </ShoppingGroup>
                        )}

                        {showWebshopGroup && (
                            <ShoppingGroup
                                title={gstate.IsSweden.get() ? "Webshop" : "Nettbutikk"}
                            >
                                {state.HasDiscountCoupon && (
                                    <ShoppingBox
                                        title={
                                            gstate.IsSweden.get()
                                                ? "3 000 kr rabatt på Samsung"
                                                : "1500 kr rabatt på nye S24"
                                        }
                                        leftContent={
                                            gstate.IsSweden.get()
                                                ? "Hela 3 000 kr i rabatt på den nya Samsung Galaxy S22-serien."
                                                : "Nye Samsung Galaxy S24 er her og nå får du ekstra 1500 kr i rabatt"
                                        }
                                        rightContent={
                                            <div style={{ position: "relative" }}>
                                                <img
                                                    src={S24Base}
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        width: "80px",
                                                        height: "140px",
                                                        left: wDim.phone ? "20px" : "45px",
                                                        top: wDim.phone ? "-95px" : "-85px"
                                                    }}
                                                />
                                            </div>
                                        }
                                        buttonText={
                                            gstate.IsSweden.get() ? "Se erbjudande" : "Se tilbud"
                                        }
                                        linkAction={() => {
                                            dialogRef.current.showDialog("discountCoupon")
                                        }}
                                    />
                                )}
                                {state.WebShopOpen && (
                                    <div
                                        style={{
                                            marginTop: state.HasDiscountCoupon ? "50px" : "0px"
                                        }}
                                    >
                                        <ShoppingBox
                                            title={
                                                gstate.IsNorway.get()
                                                    ? "Ny mobil? Se nettbutikken"
                                                    : "Ny mobil? Se vår nätbutik"
                                            }
                                            titleColor={theme.palette.primary.main}
                                            leftContent={
                                                gstate.IsNorway.get()
                                                    ? "Trenger du en ny mobil, nettbrett, AirPods, deksel, tilbehør eller til og med kanskje en Apple TV? Se vår nettbutikk."
                                                    : "Du hittar både nya modeller och begagnade mobiler och tillbehör i vår nya nätbutik."
                                            }
                                            rightContent={
                                                <div style={{ position: "relative" }}>
                                                    <img
                                                        src={Phone}
                                                        alt=""
                                                        style={{
                                                            position: "absolute",
                                                            left: wDim.phone ? "-10px" : "20px",
                                                            top: "-130px"
                                                        }}
                                                    />
                                                </div>
                                            }
                                            buttonText={
                                                gstate.IsNorway.get()
                                                    ? "Gå til nettbutikk"
                                                    : "Till nätbutiken"
                                            }
                                            linkAction={() => {
                                                dialogRef.current.openUrl(
                                                    gstate.IsNorway.get()
                                                        ? "https://nettbutikk.chilimobil.no/mobiltelefoner"
                                                        : "https://shop.chilimobil.se"
                                                )
                                            }}
                                        />
                                    </div>
                                )}
                            </ShoppingGroup>
                        )}
                        {gstate.IsNorway.get() && state.ShowInsurance && (
                            <ShoppingGroup title="Mobilforsikring">
                                <ShoppingBox
                                    disabled={state.IsPaused}
                                    title="Nyhet! Skadeforsikring"
                                    leftContent="Føste måned gratis og kun 69,- per måned. Du kan forsikre både nye og eldre mobiler."
                                    rightContent={
                                        <img src={InsuranceImage} alt="" style={{ width: "60%" }} />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        dialogRef.current.showDialog("insurance")
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {gstate.IsNorway.get() && state.ShowPodcast && (
                            <ShoppingGroup title="Podcast">
                                <ShoppingBox
                                    disabled={state.IsPaused}
                                    title="2 måneder gratis med Podimo Podcast"
                                    leftContent="Ubegrenset lytting til eksklusive podkaster og lydbøker. Ingen binding. 79,-/mnd etter gratis perioden."
                                    rightContent={
                                        <img src={PodimoLogo} alt="" style={{ width: "60%" }} />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        sessionStorage.ServiceNo = "662"
                                        sessionStorage.GroupId = 70

                                        dialogRef.current.showDialog("changeService")
                                    }}
                                />
                                <ShoppingBox
                                    disabled={state.IsPaused}
                                    title="1 måned gratis med Podimo Premium"
                                    leftContent="Ubegrenset lytting til eksklusive podkaster og lydbøker. Ingen binding. 99,-/mnd etter gratis perioden."
                                    rightContent={
                                        <img src={PodimoLogo} alt="" style={{ width: "60%" }} />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        sessionStorage.ServiceNo = "662"
                                        sessionStorage.GroupId = 70

                                        dialogRef.current.showDialog("changeService")
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {gstate.IsNorway.get() && state.ShowVPN && (
                            <ShoppingGroup title="VPN">
                                <ShoppingBox
                                    disabled={state.IsPaused}
                                    title="2 måneder gratis med NordVPN"
                                    leftContent="Ingen binding. En tjeneste som beskytter internettforbindelsen og personvernet ditt på nettet."
                                    rightContent={
                                        <img src={NordVPNLogo} alt="" style={{ width: "70%" }} />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        sessionStorage.ServiceNo = "661"
                                        dialogRef.current.showDialog("changeService")
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {gstate.IsNorway.get() && !state.IsPrepaid && state.StrimUrl && (
                            <ShoppingGroup title="Strim">
                                <ShoppingBox
                                    disabled={state.IsPaused}
                                    title="2 måneder gratis med Strim"
                                    leftContent="Du får nå 2 måneder gratis med Strim + 10% rabatt etter 2 måneder."
                                    rightContent={
                                        <img
                                            src={StrimLogo}
                                            alt=""
                                            style={{ width: "100%", borderRadius: "10px" }}
                                        />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        dialogRef.current.openUrl(state.StrimUrl)
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {state.CanRecruit && (
                            <ShoppingGroup title="Verving">
                                <ShoppingBox
                                    disabled={state.IsPaused}
                                    title="Verv en kompis"
                                    leftContent="Få rabatt hver måned, og gi rabatt hver måned når du verver en kompis."
                                    rightContent={
                                        <img
                                            src={ChiliKompisImg}
                                            alt=""
                                            style={{ width: "100%", borderRadius: "10px" }}
                                        />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        dialogRef.current.showDialog("viewFriends")
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {showKraftriket && (
                            <ShoppingGroup title="Strøm">
                                <ShoppingBox
                                    title="Strøm + mobil = enda billigere"
                                    leftContent="Siden du er Chilimobil-kunde får du nå noen av markedets beste priser på strøm fra Kraftriket."
                                    rightContent={
                                        <img
                                            src={KraftriketLogo}
                                            alt=""
                                            width={80}
                                            style={{ borderRadius: "10px" }}
                                        />
                                    }
                                    buttonText="Les mer"
                                    linkAction={() => {
                                        dialogRef.current.openUrl(state.KraftriketUrl, true)
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {showNextory && gstate.IsSweden.get() && (
                            <ShoppingGroup
                                title={gstate.IsSweden.get() ? "Underhålling" : "Lydbøker"}
                            >
                                <ShoppingBox
                                    title={
                                        gstate.IsSweden.get()
                                            ? "Nextory"
                                            : "45 dager gratis hos Nextory"
                                    }
                                    leftContent={
                                        gstate.IsSweden.get()
                                            ? "Vi erbjuder dig att lyssna eller läsa böcker och magasin i hela åtta (8) veckor hos Nextory."
                                            : "Som Chilimobil-kunde får du 45 dager ubegrenset tilgang til e-bøker og lydbøker fra Nextory."
                                    }
                                    rightContent={
                                        gstate.IsSweden.get() ? (
                                            <img src={NextoryLogo} alt="" width={80} />
                                        ) : (
                                            <img
                                                src={NextoryFirkant}
                                                alt=""
                                                width={80}
                                                style={{ borderRadius: "10px" }}
                                            />
                                        )
                                    }
                                    buttonText={gstate.IsSweden.get() ? "Beställ" : "Les mer"}
                                    linkAction={() => {
                                        dialogRef.current.openUrl(state.NextoryUrl, true)
                                    }}
                                />
                            </ShoppingGroup>
                        )}
                        {(canChangeSubscription ||
                            canOrderSubscription ||
                            canBuyDataSweden ||
                            canConvertPrepaidToPostpaid) && (
                                <ShoppingGroup
                                    title={gstate.IsNorway.get() ? "Mobilabonnement" : "Abonnemang"}
                                    weight={subscriptionGroupWeight}
                                >
                                    {canConvertPrepaidToPostpaid && (
                                        <ShoppingBox
                                            disabled={state.IsCancelled}
                                            title="Bytt til abonnement"
                                            leftContent={
                                                state.NeedCreditCheck
                                                    ? "Velg å bytte ditt kontantkort til fakturabetalt abonnement. Kredittsjekk kreves."
                                                    : "Velg å bytte ditt kontantkort til fakturabetalt abonnement."
                                            }
                                            rightContent={
                                                <img
                                                    src={PrepaidToPostpaidImg}
                                                    alt=""
                                                    style={{ width: "100%", borderRadius: "10px" }}
                                                />
                                            }
                                            buttonText="Les mer"
                                            linkAction={() => {
                                                dialogRef.current.showDialog("changePackage")
                                            }}
                                        />
                                    )}
                                    {canChangeSubscription && (
                                        <ShoppingBox
                                            disabled={state.IsPaused || state.IsCancelled}
                                            title={
                                                gstate.IsNorway.get()
                                                    ? "Endre abonnement"
                                                    : "Byt abonnemangsform"
                                            }
                                            leftContent={
                                                state.IsNorway
                                                    ? state.IsPrepaid
                                                        ? "Du kan enkelt gjøre endringer på ditt kontantkort her."
                                                        : "Du kan enkelt gjøre endringer på ditt abonnement her."
                                                    : "Du kan enkelt ändringar på ditt abonnemang här."
                                            }
                                            rightContent={
                                                <>
                                                    <div
                                                        style={{
                                                            fontFamily: theme.typography.fontFamily,
                                                            fontStyle: "normal",
                                                            fontVariant: "normal",
                                                            fontWeight: 400,
                                                            fontSize: "14px"
                                                        }}
                                                    >
                                                        {state.Subscription?.packageName}
                                                    </div>
                                                    <div>{state.IsNorway ? "Nåværende" : "Nuvarande"}</div>
                                                </>
                                            }
                                            rightContentType="text"
                                            buttonText={gstate.IsNorway.get() ? "Endre" : "Byt"}
                                            linkAction={() => {
                                                dialogRef.current.showDialog("changePackage")
                                            }}
                                        />
                                    )}
                                    {canOrderSubscription && (
                                        <ShoppingBox
                                            title={
                                                gstate.IsNorway.get()
                                                    ? "Bestill abonnement"
                                                    : "Beställ nytt abonnemang"
                                            }
                                            leftContent={
                                                gstate.IsNorway.get() ? (
                                                    "Nytt nummer, eller flytte et nummer fra et annet mobilselskap?"
                                                ) : (
                                                    <div>
                                                        Här kan du kan enkelt beställa ett nytt{" "}
                                                        <b>Mobilabonnemang</b> eller <b>Mobilt Bredband</b>.
                                                        Det är bara att välja abonnemang och sedan signera med
                                                        BankID. Klart!
                                                    </div>
                                                )
                                            }
                                            rightContent={
                                                gstate.IsNorway.get() ? (
                                                    <img
                                                        src={SimCard}
                                                        height="70"
                                                        alt=""
                                                        style={{ transform: "rotate(14deg)" }}
                                                    />
                                                ) : null
                                            }
                                            buttonText={
                                                gstate.IsNorway.get()
                                                    ? "Bestill"
                                                    : "Beställ nytt abonnemang"
                                            }
                                            linkAction={
                                                gstate.IsNorway.get()
                                                    ? () => {
                                                        dialogRef.current.openUrl(state.WebOrderUrl)
                                                    }
                                                    : () => {
                                                        vm.$dispatch({ OrderSubscription: true })
                                                    }
                                            }
                                        />
                                    )}
                                    {canBuyDataSweden && (
                                        <ShoppingBox
                                            disabled={state.IsPaused || state.IsCancelled}
                                            title="Köp mer surf"
                                            leftContent="Till våra abonnemang 6 GB samt 100 GB kan du beställa extra surf om tex din månadspott är förbrukad."
                                            rightContent={
                                                <Icon
                                                    name="money"
                                                    color={theme.palette.primary.main}
                                                    size={64}
                                                />
                                            }
                                            buttonText="Köp"
                                            linkAction={() => {
                                                dialogRef.current.showDialog("postpaidTopup")
                                            }}
                                        />
                                    )}
                                </ShoppingGroup>
                            )}
                        {canOrderSubscription && (
                            <ShoppingGroup
                                title={gstate.IsNorway.get() ? "Bredbånd" : "Bredband"}
                            >
                                <ShoppingBox
                                    title={
                                        gstate.IsNorway.get() ? "Hyttebredbånd" : "Mobilt bredband"
                                    }
                                    leftContent={
                                        gstate.IsNorway.get()
                                            ? "Bredbåndet som passer perfekt for hytte og fritidsboliger. Du slipper å overbetale når du ikke er på hytten."
                                            : "En bra lösning för flera uppkopplade prylar. Bra för lite mer tillfällig användning som helgen på landet."
                                    }
                                    rightContent={
                                        <>
                                            <div
                                                style={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "14px"
                                                }}
                                            >
                                                {state.IsSweden ? "Från 249 kr/mån" : "Fra 349,-"}
                                            </div>
                                            {gstate.IsNorway.get() && (
                                                <div style={{ fontSize: "12px" }}>Følger forbruket</div>
                                            )}
                                        </>
                                    }
                                    buttonText={gstate.IsNorway.get() ? "Bestill" : "Beställ"}
                                    linkAction={() => {
                                        dialogRef.current.openUrl(state.WebOrderUrlMBB)
                                    }}
                                />
                            </ShoppingGroup>
                        )}

                        {state.Services?.length > 0 && (
                            <ShoppingGroup
                                title={gstate.IsNorway.get() ? "Tjenester" : "Tjänster"}
                                weight={state.Services?.length}
                            >
                                {state.Services?.map((service, index) => {
                                    return (
                                        <ShoppingBox
                                            disabled={state.IsPaused || state.IsCancelled}
                                            key={index}
                                            title={service.Title}
                                            leftContent={
                                                gstate.IsNorway.get()
                                                    ? "Bredbåndet som passer perfekt for hytte og fritidsboliger. Du slipper å overbetale når du ikke er på hytten."
                                                    : "En bra lösning för flera uppkopplade prylar. Bra för lite mer tillfällig användning som helgen på landet."
                                            }
                                            rightContent={
                                                <>
                                                    {service.InitialFee !== "0 kr" && (
                                                        <div
                                                            style={{
                                                                fontFamily: theme.typography.fontFamily,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                fontWeight: 400,
                                                                fontSize: "14px"
                                                            }}
                                                        >
                                                            Etablering: {service.InitialFee}
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            fontSize:
                                                                service.InitialFee === "0 kr" ? "14px" : "12px",
                                                            fontFamily:
                                                                service.InitialFee === "0 kr"
                                                                    ? theme.typography.fontFamily
                                                                    : theme.typography.fontFamily2,
                                                            fontWeight:
                                                                service.InitialFee === "0 kr" ? 400 : 300
                                                        }}
                                                    >
                                                        {service.MonthlyFee}
                                                        {gstate.IsSweden.get() ? "/mån" : " per mnd."}
                                                    </div>
                                                </>
                                            }
                                            buttonText={gstate.IsNorway.get() ? "Bestill" : "Beställ"}
                                            linkAction={() => {
                                                sessionStorage.ServiceNo = service.ProductNo
                                                dialogRef.current.showDialog("changeService")
                                            }}
                                        />
                                    )
                                })}
                            </ShoppingGroup>
                        )}
                        {gstate.IsNorway.get() && (showNextory || showStorytel) && (
                            <ShoppingGroup
                                title={state.IsSweden ? "Underhålling" : "Lydbøker"}
                                weight={showNextory && showStorytel ? 2 : 1}
                            >
                                {showStorytel && (
                                    <ShoppingBox
                                        title={
                                            gstate.IsSweden.get()
                                                ? "Storytel"
                                                : state.StorytelTitleText
                                        }
                                        leftContent={
                                            gstate.IsSweden.get()
                                                ? "Vi erbjuder dig att testa ljud- och e-böcker från Nextory i hela 45 dagar utan kostnad"
                                                : "Over 500 000 spennende og underholdende lyd- og e-bøker. Avslutt når du vil."
                                        }
                                        rightContent={
                                            gstate.IsSweden.get() ? (
                                                <img src={NextoryLogo} alt="" width={80} />
                                            ) : (
                                                <img
                                                    src={StorytelFirkant}
                                                    alt=""
                                                    width={80}
                                                    style={{ borderRadius: "10px" }}
                                                />
                                            )
                                        }
                                        buttonText={gstate.IsSweden.get() ? "Beställ" : "Les mer"}
                                        linkAction={() => {
                                            sessionStorage.ServiceNo = "663"
                                            dialogRef.current.showDialog("changeService")
                                        }}
                                    />
                                )}
                                {showNextory && (
                                    <ShoppingBox
                                        title={
                                            gstate.IsSweden.get()
                                                ? "Nextory"
                                                : "E-bøker og lydbøker - 1 måned gratis"
                                        }
                                        leftContent={
                                            gstate.IsSweden.get()
                                                ? "Vi erbjuder dig att testa ljud- och e-böcker från Nextory i hela 45 dagar utan kostnad"
                                                : "Som Chilimobil-kunde får du 1 måned gratis med ubegrenset tilgang til e-bøker og lydbøker fra Nextory og deretter 50% rabatt i 3 måneder."
                                        }
                                        rightContent={
                                            gstate.IsSweden.get() ? (
                                                <img src={NextoryLogo} alt="" width={80} />
                                            ) : (
                                                <img
                                                    src={NextoryFirkant}
                                                    alt=""
                                                    width={80}
                                                    style={{ borderRadius: "10px" }}
                                                />
                                            )
                                        }
                                        buttonText={gstate.IsSweden.get() ? "Beställ" : "Les mer"}
                                        linkAction={() => {
                                            dialogRef.current.openUrl(state.NextoryUrl, true)
                                        }}
                                    />
                                )}
                            </ShoppingGroup>
                        )}
                    </SafeMasonryShoppingLayout>
                </TopContainer>
            ) : (
                <div id="preloader">
                    <div id="loader"></div>
                </div>
            )}
        </>
    )
}
